import './App.css';
import heistLogo from './heist_logo.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>$HEIST</h1>
        <p>CA: AScrcjfsHBMGf97GS5EaueD1hamRdgS7od4Mj6Nnpump. This is our official url <a href="https://heist.wtf" style={{ color: 'white' }}>heist.wtf</a> for $HEIST. <br />
          Follow us on <a href="https://x.com/heistWTF" style={{ color: 'white' }}>Twitter</a>. <br />
          Join our Telegram <a href="https://t.me/+ejAjDPtUy_U1NTIx" style={{ color: 'white' }}>here</a>. <br />
          Stay safe.
        </p>
        <img src={heistLogo} alt="centered" />
      </header>
      <main className="App-main">
        <h1>WEN $HEIST? LFG</h1>
        <p>
          By establishing legitimacy through our contract and tech as well as legal consequences, then I wouldn't waste my time. 
          $HEIST was inspired by all the rugs and scams that we have fallen for through the history of the blockchain. 
          It's time to end that, and let the $HEIST begin!
        </p>
      </main>
      <footer className="App-footer">
        <p>$HEIST is not a real HEIST. HEIST is a meme that promises no financial return.</p>
      </footer>
    </div>
  );
}

export default App;
